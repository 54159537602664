import {Howl} from 'howler';

const track = require("url:../assets/sound2.mp3");
const promise = document.querySelector('video').play();

if (promise !== undefined) {
    promise.then(_ => {
        //
    }).catch(error => {
        console.log("Error")
    });
}

document.body.addEventListener('click', function () {
    var sound = new Howl({
        src: [track],
        autoplay: true,
        loop: true,
        volume: 0.2,
        onend: function () {
            console.log('Finished!');
        }
    });
    sound.once('load', function () {
        sound.play();
    });
    document.body.onclick = null;
    cursor.style.display = "none";
});

var cursor = document.querySelector('.cursor');

document.addEventListener('mousemove', function (e) {
    cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`
});

